nav {
    top: 0;
    position: fixed;
    /* transition-property: all ease;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 500ms; */
    z-index: 10;
}

.nav-bar--hidden {
    transform: translateY(-100%);
}
.visible {
    /* display: flex; */
    top: 0;
    transition: top 0.4s ease-out;
}

.hidden {
    /* display: none; */
    top: -80px;
    transition: top 0.4s ease-out;
    transform: translateY(-100%);
}

/* .navbar-container {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition: transform 150ms ease-in-out;
} */

.nav-button {
    position: static;
    margin: 8px;
    padding: 10px;
    border-radius: 13px;
    background-color: transparent;
    /* background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0.5))) !important; */
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)) !important;
    font-family: 'Oxanium', sans-serif !important;
    color: #fff !important;
    font-size: 25px !important;
    line-height: 20px !important;
    font-weight: 600 !important;
}

.nav-button:hover {
    box-shadow: 1px 1px 12px 9px red;
}
.navbar-links {
    padding: 10px 20px;
    margin-right: 3%;
}

@media only screen and (max-width: 991px) {
    .navbar-links {
        background: #c8c8c8;
        width: 100% !important;
        margin-top: 10px;
    }
    .nav-button {
        background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
        position: static;
        padding: 10px 15px !important;
        border-radius: 13px;
    }
}