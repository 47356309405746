.Traits-container {
    padding: 50px 0px 100px 0;
}
.Traits-title {
    font-family: Montserrat, sans-serif;
    color: #fff;
    font-style: italic;
    font-size: 32;
    font-weight: 700;
    line-height: 36px;
}
.Traits-card {
    background: transparent;
}
.card-title {
    font-size: 24px;
    line-height: 30px;
    font-weight: 700;
}
.Traits-card-body {
    padding-left: 0px !important;
}
.traits-card-text {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
}
.Traits-card-img {
    border-radius: 0px !important;
}

.Traits-text {
    font-size: 16px;
    font-weight: 400px;
    line-height: 20px;
}

/**
 * ----------------------------------------
 * animation fade-in-fwd
 * ----------------------------------------
 */

.fade-in-fwd {
    animation: fade-in-fwd 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
@keyframes fade-in-fwd {
    0% {
        transform: translateZ(-80px);
        opacity: 0;
    }
    100% {
        transform: translateZ(0);
        opacity: 1;
    }
}
