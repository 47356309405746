nav {
    position: fixed;
    width: 100%;
}
.active {
    visibility: visible;
    transition: all 0.5s;
}
.hidden {
    visibility: hidden;
    transition: all 0.5s;
    transform: translateY(-100%);
}
.body {
    background-image: url('./images/2_ergosapiens_background_v1.png');
    overflow: visible;
    margin-right: 0px;
    margin-left: 0px;
    padding-bottom: 51px;
    background-color: #191a1c;
    background-position: 0px 0px;
    background-size: contain;
    background-attachment: fixed;
    /* height: 100vh; */
    font-family: Montserrat, sans-serif;
}
.main-container {
    color: #fff;
}
