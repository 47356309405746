.banner-img {
    width: 100%;
    max-width: 940px;
    /* margin-top: -95px; */
}
@media only screen and (max-width: 991px) {
    .banner-img {
        margin-top: 0px;
    }
}
.img-container {
    display: flex;
    justify-content: center;
}
.ergo-section {
    /* padding: 100px 5px; */
    font-family: Montserrat, sans-serif;
}
.ergo-title {
    font-size: 32px;
    line-height: 36px;
    font-weight: 700;
}
.ergo-section p {
    font-size: 14px;
}
.btn-mint {
    border: 4px solid #be2abe;
    border-radius: 14px;
    background-color: transparent;
    -webkit-transform: skew(-6deg, 0deg);
    -ms-transform: skew(-6deg, 0deg);
    transform: skew(-6deg, 0deg);
    font-family: Oxanium, sans-serif;
    color: #ff0202;
    font-size: 30px;
    padding-left: 15px;
    padding-right: 15px;
    font-weight: 700;
}
.btn-mint:hover {
    box-shadow: 0 0 12px 8px #be2abe;
    -webkit-transform: skew(-6deg, 0deg);
    -ms-transform: skew(-6deg, 0deg);
    transform: skew(-6deg, 0deg);
}
.ergo-about-container {
    padding: 100px 0;
}
.bounce-in-top {
    animation: bounce-in-top 1.1s both;
}
@keyframes bounce-in-top {
    0% {
        transform: translateY(-500px);
        animation-timing-function: ease-in;
        opacity: 0;
    }
    38% {
        transform: translateY(0);
        animation-timing-function: ease-out;
        opacity: 1;
    }
    55% {
        transform: translateY(-65px);
        animation-timing-function: ease-in;
    }
    72% {
        transform: translateY(0);
        animation-timing-function: ease-out;
    }
    81% {
        transform: translateY(-28px);
        animation-timing-function: ease-in;
    }
    90% {
        transform: translateY(0);
        animation-timing-function: ease-out;
    }
    95% {
        transform: translateY(-8px);
        animation-timing-function: ease-in;
    }
    100% {
        transform: translateY(0);
        animation-timing-function: ease-out;
    }
}
.Toastify__toast-body {
  color: #008800;
  font-family: 'Montserrat', 'sans-serif';
} 

