.mainDiv{
    position: relative; 
    text-align: left;
    margin-right: 200px;
}

a{
    text-decoration: none;
}
.package-container {
    font-family: Helvetica,Arial,sans-serif;
    z-index: 99;
}
.mainMenuItem{
    position: absolute;
    background-color: #ffffff; 
    transform-origin: center; 
    width: 200px;
    border-radius: 5px;
    border-top-width: 1px; 
    border-color: #F3F4F6; 
    box-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color); 
    --ring-color: #000000; 
    --ring-opacity: 0.05; 
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.item1{
    background-color: #F3F4F6; 
    color: #111827; 
}

.item2{
    color: black;
}

.item3{
    display: flex; 
    padding-top: 8px;
    padding-bottom: 8px; 
    padding-left: 16px;
    padding-right: 16px; 
    font-size: 13.5px;
    line-height: 20px; 
    align-items: center; 
    font-weight: bold;
}

.icon{
    margin-right: 12px; 
    color: #9CA3AF; 
    width: 20px; 
    height: 20px; 
}
#wallet-connect{
    font-size: 16px;
}

#header-wallet-image{
    width: 30px;
    height: 25.3px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}
#header-wallet
{
    display: flex;
    flex-direction: row;
    font-weight: bold;
    height: 45px;
    width: 100%;
    margin: 0 auto;
    border-radius: 5px;
    /* this is just to show the border of the item, something temporal */
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;  
    justify-content: space-evenly;
    cursor: pointer;
}

#header-wallet-wrapper{
    width: 200px;
}

.token-icon-img{

    height: 20px;
    margin-right: 16px;
}

.modal-1{
    position: fixed; 
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; 
    background-color: #6B7280; 
    --bg-opacity: 0.75; 
    transition-property: opacity; 
}

.modal-2{
    overflow-y: auto; 
    position: fixed; 
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; 
    z-index: 10; 
}


.modal-3{
    display: flex; 
    padding: 16px; 
    text-align: center; 
    display: flex; 
    justify-content: center; 
    align-items: flex-end; 
    min-height: 100%; 
}

.modal-4{
    overflow: hidden; 
    position: relative; 
    background-color: #ffffff; 
    transition-property: all; 
    text-align: left; 
    border-radius: 8px; 
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

.modal-5{
    padding-left: 16px;
    padding-right: 16px; 
    padding-bottom: 16px; 
    padding-top: 20px; 
    background-color: #ffffff; 
}

.modal-7{
    display: flex; 
    background-color: #FEE2E2; 
    display: flex; 
    flex-shrink: 0; 
    justify-content: center; 
    align-items: center; 
    width: 48px; 
    height: 48px; 
    border-radius: 9999px; 
}

.modal-8{
    margin-top: 12px; 
    text-align: center; 
}

.modal-9{
    padding-top: 12px;
    padding-bottom: 12px; 
    padding-left: 16px;
    padding-right: 16px; 
    background-color: #F9FAFB; 
}

.button-clear-wallet {
    border-radius: 5px;
    padding: 10px;
    margin: 5px 0 0 15px;
    color: #ffffff; 
    font-weight: bold;
    background-color: rgba(205, 10, 10, 0.8);
    transition-duration: 0.3s;
    border-color: transparent; 
}

.button-clear-wallet:hover {
    background-color: white;
    color: rgba(205, 10, 10, 0.8);
    border: 0.1px solid rgba(205, 10, 10, 0.8);
}

.button-clear-wallet:active {
    background-color: white;
    box-shadow: 0 5px rgba(205, 10, 10, 0.8);
    transform: translateY(2px);
  }

.button-1{
    display: inline-flex; 
    padding-top: 8px;
    padding-bottom: 8px; 
    padding-left: 16px;
    padding-right: 16px; 
    background-color: #0d6dfd;
    color: #ffffff; 
    font-size: 16px;
    line-height: 24px; 
    font-weight: 500; 
    display: inline-flex; 
    justify-content: center; 
    width: 100%; 
    border-radius: 5.5px; 
    border-width: 1px; 
    border-color: transparent; 
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05); 
}

.button-1:hover,.button-2:hover {
    background-color: white;
    color: #0d6dfd;
    border: 0.1px solid #0d6dfd
}

.button-2{
    display: inline-flex; 
    padding-top: 8px;
    padding-bottom: 8px; 
    padding-left: 16px;
    padding-right: 16px; 
    margin-top: 20px; 
    background-color: #0d6dfd;
    color: white; 
    font-size: 16px;
    line-height: 24px; 
    font-weight: 500; 
    display: inline-flex; 
    justify-content: center; 
    width: 100%; 
    border-radius: 6px; 
    border-width: 1px; 
    border-color: #D1D5DB; 
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05); 
}

@media (min-width: 640px) { 
    .modal-3{
        padding: 0; 
        align-items: center; 
    }

    .modal-4{
        margin-top: 32px;
        margin-bottom: 32px; 
        width: 100%; 
        max-width: 32px; 
    }

    .modal-5{
        padding: 24px; 
        padding-bottom: 16px; 
    }

    .modal-6{
        display: flex; 
        align-items: flex-start; 
    }

    .modal-7{
        margin-left: 0;
        margin-right: 0; 
        width: 40px; 
        height: 40px; 
    }
    
    .modal-8{
        margin-top: 0; 
        margin-left: 16px; 
        text-align: left; 
    }

    .modal-9{
        display: flex; 
        justify-content: center;
        padding-left: 24px;
        padding-right: 24px; 
        display: flex; 
        flex-direction: row-reverse;
    }

    .button-1{
        margin-left: 12px; 
        font-size: 13px;
        line-height: 12px; 
        width: auto; 
    }

    .button-2{
        margin-top: 0; 
        margin-left: 12px; 
        font-size: 13px;
        line-height: 13px; 
        width: auto; 
    }

    .dont-show-message-container {
        display: flex;
        justify-content: center; 
        align-items: center; 
        padding-right: 48px;
    }
}